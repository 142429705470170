
import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import loginBg from '../images/loginBg.svg'

function Login() {
    const api=process.env;
    const [loading,setLoading]=useState(false);
    const[error,setError]=useState(false);
    const history=useHistory();

    const{
        register,
        handleSubmit,
        reset,
        formState:{errors},
    }=useForm();

    const onSubmit=data=>{
        const login=async()=>{
            setLoading(true);
            try {
                await axios.post(`${process.env.REACT_APP_API}/login`,{
                    username:`${data.username}`,
                    password:`${data.password}`
                }).then(res=>{
                    localStorage.setItem('myToken',res.data.token)
                    setLoading(false);
                    history.push('/');
                    window.location.reload(false);
                })
            } catch (error) {
                setLoading(false);
                setError(true);
                reset();
            }
        }
        login();
    }
    return (
        <div className='w-full h-screen bg-gray-200'>
            <div className="container mx-auto px-8">
                <div className="md:grid md:grid-cols-3 md:gap-8">
                    <div className="hidden md:block col-span-2 px-16">
                        <h1 className='mt-32 text-5xl leading-24'>Sign in to <br />
                            Smaterr Dashboard
                        </h1>
                        <p className="my-8 text-gray-700 leading-7 text-xl" style={{wordSpacing:'.3rem'}}>
                            Please enter your username and password <br /> to login.
                            <a rel="noopener noreferrer" href="https://smaterr.lumbiniseed.com/#/" target="_blank" className='text-blue-600'> Visit Smaterr </a>
                        </p>
                        <img src={loginBg} alt="smaterr login" className='w-2/4 float-right -mt-16 mr-10' />
                    </div>

                    {/* login form  */}
                    <div className="bg-gray-100 p-4" style={{borderRadius:'0 0 40px 40px'}}>
                        <div className="mt-4 md:mt-36">
                        <div className="block md:hidden">
                        <h1 className='block md:hidden mt-8 text-4xl leading-24'>Sign in to <br />
                            Smaterr Dashboard
                        </h1>
                        <p className="my-4 text-gray-700 text-xl font-semibold" style={{wordSpacing:'.3rem'}}>
                            <a rel="noopener noreferrer" href="https://smaterr.lumbiniseed.com/#/" target="_blank" className='text-blue-600'> Visit Smaterr </a>
                        </p>
                        </div>

                        <form action="" onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-8'>
                        {error  ? 
                            <h4 className=' text-red-500 animate-bounce'>Login Error !</h4>
                            :   <h4></h4>
                        }
                            <input type="text" 
                                className='p-4 rounded-lg shadow-lg' 
                                placeholder="Username"
                                {...register('username',{required:true})} />
                                
                                {errors.username && <small>username is required.</small>}

                            <input type="Password" 
                                className='p-4 rounded-lg shadow-lg' 
                                placeholder="Password"
                                {...register('password',{required:true})}/>

                                {errors.password && <small>password is required.</small>}

                            <input type="submit" value={loading?'LOADING':'LOGIN'} className='btn-primary rounded-lg shadow-lg cursor-pointer' />
                        </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
