import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";

function AddFeatures() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState();
  const notify = () =>
    toast.success("Feature Added Successfully !", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const addFeatures = async () => {
      setLoading(true);
      const formData = new FormData();
      await formData.append("title", `${data.title}`);
      await formData.append("description", `${data.description}`);
      await formData.append("image", file);

      try {
        await axios
          .post(`${process.env.REACT_APP_API}/features`, formData)
          .then((res) => {
            setLoading(false);
            notify();
            reset();
          });
      } catch (error) {
        setLoading(false);
        setError(true);
        reset();
      }
    };
    addFeatures();
  };

  return (
    <div>
      <ToastContainer />
      <form
        action=''
        onSubmit={handleSubmit(onSubmit)}
        enctype='multipart/form-data'
        className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8'
      >
        {error ? <p className='text-red'></p> : ""}

        <div className='flex flex-col space-y-3'>
          <label htmlFor=''>Feature Title</label>
          <input
            type='text'
            name=''
            id=''
            className='input'
            {...register("title", { required: true })}
          />
          {errors.title && <small>Title is required !</small>}
        </div>
        <div className='flex flex-col space-y-3'>
          <label htmlFor=''>Feature Description</label>
          <input
            type='text'
            name=''
            id=''
            className='input'
            {...register("description", { required: true })}
          />
          {errors.description && <small>Description Is Required</small>}
        </div>
        <div className='flex flex-col space-y-3'>
          <label htmlFor=''>Image</label>
          <input
            type='file'
            name=''
            id=''
            className='input'
            onChange={(event) => {
              const file = event.target.files[0];
              setFile(file);
            }}
          />
        </div>

        <div className='md:row-start-5 flex flex-col space-y-3'>
          <input
            type='Submit'
            name=''
            id=''
            className='btn-primary rounded-lg cursor-pointer'
            value={loading ? "LOADING...." : "SUBMIT"}
          />
        </div>
      </form>
    </div>
  );
}

export default AddFeatures;
