import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {AiFillDelete} from 'react-icons/ai'
import { toast, ToastContainer } from 'react-toastify';

function ViewFeatures() {
    const [data,setData]=useState([]);
    const [deleted,setdeleted]=useState(false);
    const notify = () => toast.success("Feature has been deleted !",{
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    useEffect(() => {
        const fetchData=async()=>{
            await axios.get(`${process.env.REACT_APP_API}/features`).then(res=>{
                setData(res.data)
            })
        }
        fetchData();
    }, [data,deleted])

    const confirmHandler=(id)=>{
        let r=window.confirm('Are you sure to delete ?');
        if(r===true){
            handleDelete(id)
        }
        else{
            return('');
        }
        
    }
    const handleDelete= (id)=>{
        const dlt=async()=>{
            try {
                await axios.delete(`${process.env.REACT_APP_API}/features/${id}`).then(res=>{
                notify();
                setdeleted(!deleted);
            })
            } catch (error) {
                console.error(error);
                setdeleted(false);
            }
        }
        dlt();       
      

    }
    return (
        <div>
            <table className="text-left w-full">
                <tr>
                    <th>#</th>
                    <th>Feature Title</th>
                    <th>Feature Image</th>
                    <th>DELETE</th>
                </tr>
                {data.map(item=>(
                    <tr key={item.id}>
                        <td>{parseInt(data.indexOf(item))+1}</td>
                        <td>{item.title}</td>
                        <td><img src={item.image} alt={item.image} className='w-24' srcset="" /></td>
                        <td><AiFillDelete className='text-red-600 text-2xl cursor-pointer' onClick={(e)=>{confirmHandler(item.id)}}/></td>
                    </tr>
                ))}
            </table>
            <ToastContainer />

        </div>
    )
}

export default ViewFeatures
