import React from 'react'
import AddFeatures from '../components/Features/AddFeatures'
import ViewFeatures from '../components/Features/ViewFeatures'
import DashCard from '../templates/DashCard'
import Page from '../templates/Page'

function Features() {
    return (
    <Page>
      <DashCard
        btnAdd='Add Features'
        btnView='View Features'
        AddComponent={<AddFeatures />}
        ViewComponents={<ViewFeatures />}
      ></DashCard>
    </Page>
    )
}

export default Features
