import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';

function AddTutorials() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const notify = () =>
    toast.success("Tutorial Link Added Successfully !", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const addFeatures = async () => {
      setLoading(true);
      try {
        await axios
          .post(`${process.env.REACT_APP_API}/tutorials`, {
            "title":data.title,
            "link":data.link
          })
          .then((res) => {
            setLoading(false);
            notify();
            reset();
          });
      } catch (error) {
        setLoading(false);
        setError(true);
        reset();
      }
    };
    addFeatures();
  };
    return (
        <form action='' 
        onSubmit={handleSubmit(onSubmit)}
        enctype='multipart/form-data'
        className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8'>
      <div className='flex flex-col space-y-3'>
        <label htmlFor=''>Tutorial Title</label>
        <input type='text' name='' id='' className='input'
        {...register('title',{required:true})} />
      </div>
      <div className='flex flex-col space-y-3'>
        <label htmlFor=''>Tutorial Link</label>
        <input type='text' name='' id='' className='input'
        {...register('link',{required:true})} />
      </div>
      
      <div className='flex flex-col space-y-3'>
        <input type='Submit' name='' id='' className='btn-primary rounded-lg cursor-pointer' value={loading?'LOADING...':'SUBMIT'} />
      </div>
      <ToastContainer />

    </form>
    )
}

export default AddTutorials
