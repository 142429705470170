import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

function AddHT() {
  const [loading, setLoading] = useState(false);
  const [erroe, setError] = useState(false);
  const notify = () => toast.success("Home Title Updated Successfully !",{
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const update = async () => {
      setLoading(true);
      try {
        await axios
          .put(`${process.env.REACT_APP_API}/home_title/1`, {
            title: `${data.title}`,
            description: `${data.description}`,
          })
          .then((res) => {
            setLoading(false);
            notify();
            reset();
          });
      } catch (error) {
        setLoading(false);
        setError(true);
        reset();
      }
    };
    update();
  };
  return (
    <div>
      <ToastContainer />
      <form
        action=''
        onSubmit={handleSubmit(onSubmit)}
        className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8'
      >
        <div className='flex flex-col space-y-3'>
          <label htmlFor=''>Home Title</label>
          <input
            type='text'
            className='input'
            {...register("title", { required: true })}
          />
          {errors.title && <small>Title is required !</small>}
        </div>
        <div className='flex flex-col space-y-3'>
          <label htmlFor=''>Title Description</label>
          <input
            type='text'
            className='input'
            {...register("description", { required: true })}
          />
          {errors.descripiton && <small>Description is required !</small>}
        </div>

        <div className='flex flex-col space-y-3'>
          <input
            type='Submit'
            name=''
            id=''
            className='btn-primary rounded-lg cursor-pointer'
            value='SUBMIT'
          />
        </div>
      </form>
    </div>
  );
}

export default AddHT;
