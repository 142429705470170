import React from 'react'
import AddTutorials from '../components/AppTutorials/AddTutorials'
import ViewTutorials from '../components/AppTutorials/ViewTutorials'
import DashCard from '../templates/DashCard'
import Page from '../templates/Page'

function AppTutorials() {
    return (
        <Page>
      <DashCard
        btnAdd='Add App Tutorials'
        btnView='View App Tutorials'
        AddComponent={<AddTutorials />}
        ViewComponents={<ViewTutorials />}
      ></DashCard>
    </Page>
    )
}

export default AppTutorials
