import React from 'react'
import AddNotice from '../components/Notice/AddNotice'
import ViewNotice from '../components/Notice/ViewNotice'
import DashCard from '../templates/DashCard'
import Page from '../templates/Page'

function Notice() {
    return (
        <Page>
        <DashCard
          btnAdd='Add Notice'
          btnView='View Notice'
          AddComponent={<AddNotice />}
          ViewComponents={<ViewNotice />}
        ></DashCard>
      </Page>
    )
}

export default Notice
