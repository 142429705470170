import React from 'react'
import AddBlog from '../components/Blog/AddBlog'
import ViewBlog from '../components/Blog/ViewBlog'
import DashCard from '../templates/DashCard'
import Page from '../templates/Page'

function Blog() {
    return (
        <Page>
      <DashCard
        btnAdd='Add Blog'
        btnView='View Blog'
        AddComponent={<AddBlog />}
        ViewComponents={<ViewBlog />}
      ></DashCard>
    </Page>
    )
}

export default Blog
