import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";

function AddMT() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState();
  const notify = () =>
    toast.success("New Team Member has been uploaded Successfully !", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const addManagementTeam = async () => {
      setLoading(true);
      const formData = new FormData();
      await formData.append("name", `${data.name}`);
      await formData.append("job", `${data.job}`);
      await formData.append("fb", `${data.fb}`);
      await formData.append("insta", `${data.insta}`);
      await formData.append("twit", `${data.twit}`);
      await formData.append("image", file);
      try {
        await axios.post(`${process.env.REACT_APP_API}/mt`, formData).then((res) => {
          setLoading(false);
          notify();
          reset();
        });
      } catch (error) {
        setLoading(false);
        setError(true);
        reset();
      }
    };
    addManagementTeam();
  };

  return (
    <div>
      <ToastContainer />
      <form
        action=''
        onSubmit={handleSubmit(onSubmit)}
        enctype='multipart/form-data'
        className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8'
      >
        {error ? <p className='text-red'></p> : ""}

        <div className='flex flex-col space-y-3'>
          <label htmlFor=''>Name of Person</label>
          <input
            type='text'
            name=''
            id=''
            className='input'
            {...register("name", { required: true })}
          />
          {errors.name && <small>Name is required !</small>}
        </div>
        <div className='flex flex-col space-y-3'>
          <label htmlFor=''>Job Title</label>
          <input
            type='text'
            name=''
            id=''
            className='input'
            {...register("job", { required: true })}
          />
          {errors.job && <small>Job Title is required !</small>}
        </div>
        <div className='flex flex-col space-y-3'>
          <label htmlFor=''>Upload Image (250*250)</label>
          <input
            type='file'
            name=''
            id=''
            className='input'
            onChange={(event) => {
              const file = event.target.files[0];
              setFile(file);
            }}
          />
        </div>
        <div className='flex flex-col space-y-3'>
          <label htmlFor=''>Facebook Link</label>
          <input
            type='text'
            name=''
            id=''
            className='input'
            {...register("fb")}
          />
        </div>
        <div className='flex flex-col space-y-3'>
          <label htmlFor=''>Instagram Link</label>
          <input
            type='text'
            name=''
            id=''
            className='input'
            {...register("insta")}
          />
        </div>
        <div className='flex flex-col space-y-3'>
          <label htmlFor=''>Twitter Link</label>
          <input
            type='text'
            name=''
            id=''
            className='input'
            {...register("twit")}
          />
        </div>
        <div className='flex flex-col space-y-3'>
          <input
            type='Submit'
            name=''
            id=''
            className='btn-primary rounded-lg cursor-pointer'
            value={loading ? "LOADING...." : "SUBMIT"}
          />
        </div>
      </form>
    </div>
  );
}

export default AddMT;
