import React from "react";
import Page from '../templates/Page';
import { FaCalendarDay } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
import { Link, useHistory } from "react-router-dom";
import nav from "../Nav/navigation.json";
import { CgLogOut } from "react-icons/cg";

function Home() {
  const history=useHistory();
    const handleDelete=()=>{
        localStorage.removeItem("myToken");
        history.push('/login');
        window.location.reload(false);
    }
  return (
    <Page>
<div className='bg-gray-100 min-h-screen'>
      <div className='container mx-auto mt-10 md:mt-0 md:pt-20'>
        <div className='px-8 bg-white p-8 shadow-xl'>
          <div className='flex flex-col space-y-4 md:flex-row md:items-center justify-between'>
            <h1>Welcome to the Smaterr Dashboard</h1>
            <div className='flex items-center text-xl cursor-pointer border p-2 px-4 shadow-md rounded'>
              <IoMdPerson className='mr-2' />
              <Link to='/profile'><p>Admin Profile</p></Link>
              <CgLogOut className='hidden md:block ml-2 pl-2 text border-l-2 text-3xl mr-2' />
              <p className='hidden md:block' onClick={handleDelete}>Logout</p>
            </div>
          </div>
          <p className='my-6 md:my-3 flex items-center'>
            <FaCalendarDay className='mr-2 ' /> {new Date().toDateString()}
          </p>
          <div className='mt-20 grid grid-cols-2 md:grid-cols-4 gap-8'>
            {nav.map((items) => (
              <Link to={items.to}>
                <div className='border p-4 shadow rounded-md'>
                  <h3>{items.title}</h3>
                  <p className='my-2'>Add/View {items.title}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
    </Page>
  );
}

export default Home;
