import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { toast, ToastContainer } from "react-toastify";

function AddBlog() {
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState();
  const notify = () =>
    toast.success("Blog Added Successfully !", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleBody = (e) => {
    setBody(e);
  };
  const onSubmit = (data) => {
    const addBlog = async () => {
      setLoading(true);
      const formData = new FormData();
      await formData.append("date", `${new Date().toDateString()}`);
      await formData.append("title", `${data.title}`);
      await formData.append("image", file);
      await formData.append("description", `${body}`);

      try {
        await axios.post(`${process.env.REACT_APP_API}/blog`, formData).then((res) => {
          setLoading(false);
          console.log(res)
          notify();
          reset();
          setBody('');
        });
      } catch (error) {
        setLoading(false);
        setError(true);
        reset();
      }
    };
    addBlog();
  };

  return (
    <form
      action=''
      onSubmit={handleSubmit(onSubmit)}
      enctype='multipart/form-data'
      className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8'
    >
      {/* {error ? <p className='text-red'>Error</p> : ""} */}
      <div className='flex flex-col space-y-3'>
        <label htmlFor=''>Blog Title</label>
        <input
          type='text'
          name=''
          id=''
          className='input'
          {...register("title")}
        />
      </div>
      <div className='flex flex-col space-y-3'>
        <label htmlFor=''>Blog Image</label>
        <input
          type='file'
          name=''
          id=''
          className='input'
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
        />
      </div>
      <div className='md:col-span-2 flex flex-col space-y-3'>
        <label htmlFor=''>Notice Description</label>
        <ReactQuill
          placeholder='Write your Blog Article here'
          modules={AddBlog.modules}
          formats={AddBlog.formats}
          onChange={handleBody}
          value={body}
        />{" "}
      </div>

      <div className='flex flex-col space-y-3 mt-24'>
        <input
          type='Submit'
          name=''
          id=''
          className='btn-primary rounded-lg cursor-pointer'
          value={loading?'LOADING....':'SUBMIT'}
        />
      </div>
      <ToastContainer />

    </form>
  );
}
AddBlog.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
};

AddBlog.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
];

export default AddBlog;
