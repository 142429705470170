import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {AiFillDelete} from 'react-icons/ai'

function ViewHT() {
    const [data,setData]=useState([]);
    console.log(data);
    useEffect(() => {
        const fetchData=async()=>{
            await axios.get(`${process.env.REACT_APP_API}/home_title`).then(res=>{
                console.log(res)
                setData(res.data)
            })
        }
        fetchData();
    }, [])

    const handleDelete=()=>{
        
    }
    return (
        <div>
            <table className="text-left w-full">
                <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Description</th>
                </tr>
                {data.map(item=>(
                    <tr key={item.id}>
                        <td>{parseInt(data.indexOf(item))+1}</td>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                    </tr>
                ))}
            </table>
        </div>
    )
}

export default ViewHT
