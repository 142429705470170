import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { toast, ToastContainer } from 'react-toastify';

function AddNotice() {
    const [body, setBody] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const notify = () =>
    toast.success("Notice Added Successfully !", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleBody = (e) => {
    setBody(e);
  };

  const onSubmit = (data) => {
    const addNotice = async () => {
      setLoading(true);
      

      try {
        await axios.post(`${process.env.REACT_APP_API}/notice`, {
          "date": `${new Date().toDateString()}`,
          "title":`${data.title}`,
          "description":`${body}`
        }).then((res) => {
          setLoading(false);
          notify();
          setBody('');
          reset();
        });
      } catch (error) {
        setLoading(false);
        setError(true);
        reset();
      }
    };
    addNotice();
  };

    return (
        <form action='' onSubmit={handleSubmit(onSubmit)} className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8'>
      <div className='flex flex-col space-y-3'>
        <label htmlFor=''>Notice Title</label>
        <input type='text' name='' id='' className='input'
        {...register('title')} />
      </div>
      <div className='flex flex-col space-y-3'>
        <label htmlFor=''>Notice Description</label>
        <ReactQuill theme="snow" value={body} onChange={setBody}/>
      </div>
      
      <div className='flex flex-col mt-24 space-y-3'>
        <input type='Submit' name='' id='' className='btn-primary rounded-lg cursor-pointer' value={loading?'LOADING....':'SUBMIT'} />
      </div>
      <ToastContainer />

    </form>
    )
}

export default AddNotice
