
import React, { useEffect, useState } from 'react'
import { HashRouter, Route, Link, useHistory, Redirect, Switch } from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import './css/master.scss';
import jwt from 'jsonwebtoken'
import HomeTitle from './pages/HomeTitle';
import Profile from './pages/Profile';
import ManagementTeam from './pages/ManagementTeam';
import Features from './pages/Features';
import AppTutorials from './pages/AppTutorials';
import Blog from './pages/Blog';
import Notice from './pages/Notice';
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const [auth,setAuth]=useState(false);
  const history=useHistory();

  useEffect(() => {
    const s1='myStrongJwtKey1223';
    let token=localStorage.getItem('myToken');
     if(token!=null){
       jwt.verify(token,`${s1}`,function(err,decode){
         if(err){
           localStorage.removeItem('myToken');
           setAuth(false);
            window.location.reload(false);
            history.push('/login');
            
          }
         else{
           setAuth(true);
         }
       })
     }
  }, [history])
  return (
    auth?
      <HashRouter>
        <Route path="/" exact component={Home} />
        <Route path="/h_title" exact component={HomeTitle } />
        <Route path="/profile" exact component={Profile} />
        <Route path="/Management_team" exact component={ManagementTeam} />
        <Route path="/features" exact component={Features} />
        <Route path="/app_tutorials" exact component={AppTutorials} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/notice" exact component={Notice} />
        <Redirect to="/" />
      </HashRouter>
      :
      <HashRouter>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Redirect to="/login" component={Login} />
        </Switch>
      </HashRouter>
  )
}

export default App

