import React from "react";
import AddHT from "../components/HomeTitle/AddHT";
import ViewHT from "../components/HomeTitle/ViewHT";

import DashCard from "../templates/DashCard";
import Page from "../templates/Page";

function HomeTitle() {
  return (
    <Page>
      <DashCard
        btnAdd='Add Home Title'
        btnView='View Home Title'
        AddComponent={<AddHT />}
        ViewComponents={<ViewHT />}
      ></DashCard>
    </Page>
  );
}

export default HomeTitle;
