import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AiFillDelete } from 'react-icons/ai';
import { toast, ToastContainer } from 'react-toastify';

function ViewNotice() {
    const [data,setData]=useState([]);
    const [deleted,setdeleted]=useState(false);
    const notify = () => toast.success("Team Member has been deleted !",{
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    useEffect(() => {
        const fetchData=async()=>{
            await axios.get(`${process.env.REACT_APP_API}/notice`).then(res=>{
                console.log(res)
                setData(res.data)
            })
        }
        fetchData();
    }, [data,deleted])
    const confirmHandler=(id)=>{
        let r=window.confirm('Are you sure to delete ?');
        if(r===true){
            handleDelete(id)
        }
        else{
            return('');
        }
        
    }
   
    const handleDelete= (id)=>{
        const dlt=async()=>{
            try {
                await axios.delete(`${process.env.REACT_APP_API}/notice/${id}`).then(res=>{
                notify();
                setdeleted(!deleted);
            })
            } catch (error) {
                console.error(error);
                setdeleted(false);
            }
        }
        dlt();       
      

    }
    return (
        <div>
            <table className="text-left w-full">
                <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Notice Title</th>
                    <th>DELETE</th>
                </tr>
                {data.map(item=>(
                    <tr key={item.id}>
                        <td>{parseInt(data.indexOf(item))+1}</td>
                        <td>{item.date}</td>
                        <td>{item.title}</td>
                        <td><AiFillDelete className='text-red-600 text-2xl cursor-pointer'  onClick={(e)=>{confirmHandler(item.id)}} /></td>
                    </tr>
                ))}
            </table>
            <ToastContainer />

        </div>
    )
}

export default ViewNotice
