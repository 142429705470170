import React, { useEffect, useState } from "react";
import Page from "../templates/Page";
import decode from "jwt-decode";
import { IoMdClose } from "react-icons/io";
import { GoCheck } from "react-icons/go";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";

function Profile() {
  const [prevPass, setPrevPass] = useState("");
  const [pass, setPass] = useState("");
  const [validPass, setValidPass] = useState(null);
  const history=useHistory();
  
  useEffect(() => {
    let token = localStorage.getItem("myToken");
    const decoded = decode(token);
    setPrevPass(decoded.user[0].password);

    if (pass === prevPass) {
      setValidPass(true);
      console.log("validPass", validPass);
    } else {
      setValidPass(false);
    }
  }, [validPass, prevPass, pass]);

  
  const handlePassCheck = (e) => {
    setPass(e.target.value);
  };

  const[loading,setLoading]=useState(false);
  const{
    register,
    handleSubmit,
    reset,
    formState:{errors},
  }=useForm();

  const onSubmit=data=>{
    const update=async()=>{
      setLoading(true);
      try {
        await axios.put('http://localhost:5000/login/1',{
          username:`${data.username}`,
          password:`${data.password}`
        }).then(res=>{
          setLoading(false);
          localStorage.removeItem('myToken');
          history.push('/login');
          window.location.reload(false);
        })
      } catch (error) {
        setLoading(false);
        reset();
      }
    }
    update();
  }

  return (
    <Page>
      <div className='bg-gray-100 min-h-screen'>
        <div className='p-4 md:p-0 md:grid md:grid-cols-5'>
          <div className='col-start-2 col-span-3'>
            <div className='my-8'>
              <h1 className='text-gray-800'>Update Profile</h1>
              <p>You can update your dashboard login credentials from here</p>
            </div>
            <div className='border bg-white p-8'>
              <form action='' onSubmit={handleSubmit(onSubmit)}  className='flex flex-col space-y-4'>
                <label htmlFor='usernames'>New Username</label>
                <input
                  type='text'
                  className='border p-2 rounded-md shadow'
                  id='usernames'
                  {...register('username',{required:true})}
                />
                {errors.username&&<small>username is required !</small>}

                {validPass ? (
                  <label
                    htmlFor='pass'
                    className='flex items-center text-green-700'
                  >
                    <GoCheck />
                    Previous Password
                  </label>
                ) : (
                  <label
                    htmlFor='pass'
                    className={validPass?'flex items-center text-black':'flex items-center text-red-700'}
                  >
                    {validPass?'':<IoMdClose />}
                    Previous Password
                  </label>
                )}

                <input
                  type='password'
                  className='border p-2 rounded-md shadow'
                  id='pass'
                  onChange={handlePassCheck}
                />
                
                {validPass?
                    <div className="flex flex-col space-y-4">
                          <label htmlFor='pass'>New Password</label>
                 <input
                   type='password'
                   className='border p-2 rounded-md shadow'
                   id='pass'
                   {...register('password',{required:true})}                   
                 />
                 {errors.password&&<small>Password is required !</small>}
                    </div>
                :''}

                {validPass ? 
                <input type='submit' 
                  className='btn-primary rounded-full w-28'
                  value={loading?'LOADING...':'UPDATE'}/>
                :''}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Profile;
